import { App, Component, computed } from 'vue';

import { openLoginModal } from '@/shared/instances/login-modal';
import { useUserStore } from '@/shared/pinia/modules/user';

import { createCoreVueApp } from './core-vue-app';

export const mountCommunityComponentAsApp = async (
  component: Component,
  target: Element | string,
  props?: Record<string, unknown>,
  appDecorator?: ((app: App) => void | Promise<void>) | null
) => {
  const coreApp = await createCoreVueApp(
    component,
    target,
    props,
    appDecorator
  );

  const userStore = useUserStore();
  const profile = computed(() => userStore.communityProfile);

  coreApp
    .provide('profile', profile)
    .provide('initLogin', openLoginModal)
    .mount(target);
};
